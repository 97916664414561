define(['lodash', 'reactDOM', 'componentsCore', 'components', 'santa-components'], function (_, reactDOM, componentsCore, components, santaComponents) {
    'use strict';

    const {santaTypesDefinitions} = santaComponents;
    const fillMixin = components.mediaCommon.mediaLogicMixins.fill;
    const {pinnedLayerHelper} = santaComponents.utils;

    const popupContainer = {
        displayName: 'PopupContainer',
        mixins: [
            fillMixin,
            componentsCore.mixins.skinBasedComp,
            componentsCore.mixins.cyclicTabbingMixin,
            componentsCore.mixins.createChildComponentMixin,
            santaComponents.mixins.inlineContentMixin,
            santaComponents.mixins.overflowWrapperMixin
        ],
        propTypes: {
            isSiteMembersDialogOpen: santaComponents.santaTypesDefinitions.SiteMembersSantaTypes.isSiteMembersDialogOpen,
            closePopupPage: santaComponents.santaTypesDefinitions.popupPage.close,
            isMeshLayoutMechanism: santaComponents.santaTypesDefinitions.Layout.isMeshLayoutMechanism,
            windowKeyboardEvent: santaComponents.santaTypesDefinitions.SiteAspects.windowKeyboardEvent.isRequired,
            isViewerMode: santaComponents.santaTypesDefinitions.isViewerMode.isRequired,
            defaultContentArea: santaComponents.santaTypesDefinitions.Container.defaultContentArea.isRequired,
            pinnedChildrenIDs: santaTypesDefinitions.Component.pinnedChildrenIDs,
            isResponsive: santaTypesDefinitions.RendererModel.isResponsive,
            wixTopAdsHeight: santaTypesDefinitions.WixAds.wixTopAdsHeight
        },
        statics: {
            behaviors: components.mediaCommon.mediaBehaviors.fill
        },
        forceRedrawOnAnimationEnded: true,
        previousVisibilityState: 'hidden',

        getDefaultSkinName() {
            return 'wysiwyg.viewer.skins.stripContainer.DefaultStripContainer';
        },

        componentWillMount() {
            if (this.props.windowKeyboardEvent) {
                this.props.windowKeyboardEvent.registerToEscapeKey(this);
            }

            if (this.props.isViewerMode) {
                this.registerOnAnimationEnd(function () {
                    const rootRef = this.refs[''];
                    if (rootRef) {
                        reactDOM.findDOMNode(rootRef).focus();
                    }
                    this.unregisterOnAnimationEnd();
                });
            }
        },
        componentDidMount() {
            this._focusedElementBeforeLightbox = _.get(window, 'document.activeElement');
            if (this.props.isViewerMode) {
                const node = reactDOM.findDOMNode(this.refs['']);
                if (!node.contains(window.document.activeElement)) {
                    reactDOM.findDOMNode(this.refs['']).focus();
                }
            }
        },
        componentWillUnmount() {
            if (this.props.windowKeyboardEvent) {
                this.props.windowKeyboardEvent.unRegisterKeys(this);
            }
            this.forceRedrawOnAnimationEnded = false;
            if (this._focusedElementBeforeLightbox) {
                this._focusedElementBeforeLightbox.focus();
            }
        },

        isScreenWidth() {
            return _.get(this.props, 'compProp.alignmentType') === 'fullWidth';
        },

        isFullHeight() {
            return _.get(this.props, 'compProp.alignmentType') === 'fullHeight';
        },

        onEscapeKey() {
            this.props.closePopupPage();
        },

        renderChildrenForMesh() {
            const {id, browser, pinnedChildrenIDs, wixTopAdsHeight} = this.props;

            const isReactChildPinned = reactChild => !!pinnedChildrenIDs[reactChild.props.id];
            const [pinnedChildren, notPinnedChildren] = _.partition(this.props.children, isReactChildPinned);

            const isMeshComponentPinned = meshComp => _.get(meshComp, 'isFixed', false) === true && _.has(meshComp, 'docked');
            const pinnedMeshComps = _.filter(this.props.meshParams.components, isMeshComponentPinned);

            return [
                pinnedLayerHelper.createPinnedLayer({id: `${id}-pinned-layer`, children: pinnedChildren, childrenMeshParams: pinnedMeshComps, browser, wixTopAdsHeight}),
                ...this.getChildrenRenderer(_.assign({
                    children: notPinnedChildren
                }, this.isScreenWidth() && {
                    contentArea: this.props.defaultContentArea
                }, this.isFullHeight() && {
                    overrides: {
                        fitToContentHeight: true
                    }
                }))
            ];
        },

        getSkinProperties() {
            const isMeshLayout = this.props.isMeshLayoutMechanism;
            const a11y = _.get(this.props, ['compData', 'a11y'], {});

            return {
                '': _.assign({
                    onKeyDown: this.preventTabbingOut,
                    'data-docking': JSON.stringify(_.pick(this.props.compProp, ['verticalOffset', 'verticalAlignment', 'horizontalOffset', 'horizontalAlignment', 'alignmentType'])),
                    role: 'dialog',
                    style: _.assign({
                        //restore pointer events after inlineContent of page component, a parent of popupContainer,
                        //got pointerEvents none to make possible click events on overlay
                        pointerEvents: 'auto'
                    }, this.isFullHeight() && !isMeshLayout && {minHeight: '100vh'}),
                    'aria-label': a11y.label,
                    tabIndex: -1
                }, isMeshLayout && {'data-is-mesh': true}),
                background: this.props.isResponsive ? {} : this.createFillLayers({bgStyle: {height: 'auto'}}),
                inlineContent: this.getInlineContent({
                    children: isMeshLayout ? this.renderChildrenForMesh() : this.getChildrenRenderer(this.isScreenWidth() && {contentArea: this.props.defaultContentArea})
                })
            };
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.PopupContainer', popupContainer);

    return popupContainer;
});
